var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1100"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-card',[_c('v-card-title',{staticClass:"px-7"},[_c('div',{staticClass:"headline font-weight-bold font--text pb-4"},[_vm._v(" IPSec Phase 2 ")])]),_c('v-divider'),_c('br'),_c('v-card-text',[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("ID")]),_c('th',[_vm._v("Mode")]),_c('th',[_vm._v("Protocol")]),_c('th',[_vm._v("Pfs Group")]),_c('th',[_vm._v("Lifetime")]),_c('th',[_vm._v("Local IP Subnet")]),_c('th',[_vm._v("Remote IP Subnet")]),_c('th',[_vm._v("Description")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v("Action")])])]),_c('tbody',{staticStyle:{"font-weight":"600"}},_vm._l((_vm.dataPhaseTwo),function(val,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(i+1))]),_c('td',[_vm._v(_vm._s((val.mode)))]),_c('td',[_vm._v(_vm._s(_vm.uppercaseWord(val.protocol)))]),_c('td',[_vm._v(_vm._s((val.pfsgroup)))]),_c('td',[_vm._v(_vm._s((val.lifetime)))]),_c('td',[_vm._v(_vm._s((val.localid_type == "lan" ? _vm.uppercaseWord(val.localid_type) : val.localid_address.concat("/",val.localid_netbits))))]),_c('td',[_vm._v(_vm._s((val.remoteid_address.concat("/",val.remoteid_netbits))))]),_c('td',[_vm._v(_vm._s(val.descr))]),_c('td',[_c('popupquote',{attrs:{"offsetTop":20,"documentid":'popup' + val.id,"offset-y":"","allow-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('label',_vm._b({staticClass:"primary--text linkpointer",staticStyle:{"position":"relative","white-space":"nowrap"},on:{"click":function ($event) {
                        on.click($event);
                    }}},'label',attrs,false),[_vm._v("More "),_c('v-icon',{class:{
                        rotate: val.open,
                    },attrs:{"id":'popup' + val.id,"color":"primary"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true),model:{value:(val.open),callback:function ($$v) {_vm.$set(val, "open", $$v)},expression:"val.open"}},[_c('v-list',[(val.disabled == false)?_c('v-list-item',{on:{"click":function($event){return _vm.doActionStatusPhase2(val.id,val.disabled)}}},[_c('v-list-item-title',{staticClass:"warning--text"},[_vm._v("Disable")])],1):_c('v-list-item',{on:{"click":function($event){return _vm.doActionStatusPhase2(val.id,val.disabled)}}},[_c('v-list-item-title',{staticClass:"primary--text"},[_vm._v("Enable")])],1),(val.localid_type != 'lan')?_c('v-list-item',{on:{"click":function($event){return _vm.doDeletePhase2(val.id)}}},[_c('v-list-item-title',{staticClass:"error--text"},[_vm._v("Delete")])],1):_vm._e()],1)],1)],1)])}),0)])],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"accent fz-14",attrs:{"block":"","height":"45","depressed":""},on:{"click":function($event){_vm.modal = false}}},[_c('span',[_vm._v("Close")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }